import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = makeStyles()((theme) => ({
	imageContainer: {
		width: '100%',
		height: 'auto',
		display: 'flex',
		paddingBottom: theme.spacing.xsmall,

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},
	},
	row: {
		display: 'flex',
		paddingBottom: theme.spacing.xsmall,
		gap: theme.spacing.xsmall,

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},
	},
	image: {
		height: 'auto',
		width: '100%',
		objectFit: 'contain',
	},
	heading: {
		paddingBottom: theme.spacing.xsmall,

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},
	},
	paragraph: {
		paddingBottom: theme.spacing.xsmall,

		/** Fix for un avoidable trailing white spaces on dynamic pages RichText */
		'&:last-of-type': {
			paddingBottom: 0,
		},

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},
	},
	buttonLink: {
		paddingBottom: theme.spacing.xsmall,
		textDecoration: 'none',
		display: 'inline-flex',

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},
	},

	link: {
		display: 'inline-flex',
		textDecoration: 'none',
	},
	contentfulTable: {
		borderSpacing: 0,
		display: 'block',
		paddingBottom: theme.spacing.xsmall,

		[getFromBreakpoint('md')]: {
			paddingBottom: theme.spacing.small,
		},

		tbody: { display: 'block' },
		tr: {
			borderBottom: theme.table.border,
			display: 'block',
			padding: `${theme.spacing.small} 0`,
		},
		th: {
			font: theme.table.cell.head.font,
			textAlign: 'left',
			p: {
				font: theme.table.cell.head.font, // Contentful adds paragraphs inside the tables automatically
				marginBottom: 0,
			},
			'&:first-of-type': {
				minWidth: '175px', // No design token for this one
			},
		},
		td: {
			font: theme.table.cell.default.font,
			p: {
				font: theme.table.cell.default.font, // Contentful adds paragraphs inside the tables automatically
				marginBottom: 0,
			},
			'&:first-of-type': {
				color: theme.body.foreground.color.dimmed,
				minWidth: '175px', // No design token for this one
				p: {
					color: theme.body.foreground.color.dimmed,
				},
			},
		},
		['th, td']: {
			paddingRight: theme.spacing.medium,

			'&:last-of-type': {
				paddingRight: 0,
			},
		},
	},
}));

export default useStyles;
