import { FC, useCallback, useMemo } from 'react';

import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { Link } from '@vakantiesnl/components/src/atoms/Link';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';
import { PriceShape } from '@vakantiesnl/components/src/molecules/PriceShape/PriceShape';
import { useLinkCardUrl } from '@vakantiesnl/components/src/utils/contentful';
import ImageComponent from '@vakantiesnl/image-component';
import { ContentfulImageParams } from '@vakantiesnl/image-component/src/loaders/contentful';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { BandedSearchPageCardColor, BandedSearchPageCardVariant, ContentfulLink, LinkCard } from '@vakantiesnl/types';

import { useStyles } from './BrandedSearchRelatedCard.style';

interface Props {
	card: LinkCard | ContentfulLink;
	departureDate?: string[] | null;
	onClick?: ({ label, link }: { label: string; link: string }) => void;
	variant?: BandedSearchPageCardVariant | null;
	index: number;
	color?: BandedSearchPageCardColor | null;
}

const contentfulParams: ContentfulImageParams = {
	fm: 'webp',
	fit: 'fill',
	w: 480,
	h: 360,
	f: 'center',
	disableScaling: true,
};

const verticalImageContentfulParams: ContentfulImageParams = {
	fm: 'webp',
	w: 960,
	h: 768,
	disableScaling: true,
};

export const BrandedSearchRelatedCard: FC<Props> = ({ card, departureDate, variant, color, index, onClick }) => {
	const { classes, cx } = useStyles();

	const microCopies = useMicroCopyContext();
	const isLinkCard = 'imgSrc' in card;
	const href = useLinkCardUrl(card, departureDate);

	const colorVariant = useMemo(() => {
		if (variant == 'textIcon' || variant === 'textOnly') {
			if (!!color && color !== 'rainbow') {
				switch (color) {
					case 'green':
						return classes.green;
					case 'yellow':
						return classes.yellow;
					case 'blue':
						return classes.blue;
					case 'purple':
						return classes.purple;
					case 'orange':
						return classes.orange;
				}
			} else if (index >= 0) {
				switch (index) {
					case 0:
						return classes.green;
					case 1:
						return classes.yellow;
					case 2:
						return classes.blue;
					case 3:
						return classes.purple;
					case 4:
						return classes.orange;
					default:
						return classes.green;
				}
			}
		}
	}, [classes.blue, classes.green, classes.orange, classes.purple, classes.yellow, color, index, variant]);

	const handleOnClick = useCallback(() => {
		onClick && onClick({ label: card.title, link: href });
	}, [card.title, href, onClick]);

	const cardImage = useMemo(() => {
		if (isLinkCard) {
			if (variant === 'verticalImage') return card.verticalImgSrc;

			return card.cardImage ?? card.imgSrc;
		}
	}, [card, isLinkCard, variant]);

	return (
		<Link
			href={href}
			onClick={handleOnClick}
			className={cx(classes.card, colorVariant, {
				[classes.colorCard]: variant === 'textIcon' || variant === 'textOnly',
				[classes.textOnlyCard]: variant === 'textOnly',
				[classes.verticalCard]: variant === 'verticalImage',
			})}
			data-cy="branded-search-related-card"
		>
			{isLinkCard && (variant === 'image' || variant === 'verticalImage') && (
				<>
					<div className={classes.imageContainer}>
						{!!cardImage && (
							<ImageComponent
								useLoader="Contentful"
								contentfulParams={
									variant === 'verticalImage' ? verticalImageContentfulParams : contentfulParams
								}
								alt={card.title}
								src={cardImage}
								className={classes.image}
								width={variant === 'verticalImage' ? 384 : 240}
								height={variant === 'verticalImage' ? 480 : 180}
							/>
						)}
					</div>
					{card.cheapestPrice && variant === 'image' && (
						<div className={classes.cheapestPriceBlock}>
							<PriceShape
								prefix={microCopies['common.from']}
								postfix={microCopies['common.perPersonShort']}
								price={card.cheapestPrice}
							/>
						</div>
					)}
				</>
			)}
			<div className={classes.content}>
				{variant === 'textIcon' && card.iconType && <Icon type={card.iconType} size="large" />}
				<Typography as="h5" variant="headingSm" className={classes.label}>
					{card.title}
				</Typography>
			</div>
		</Link>
	);
};
